import InputMask from "react-input-mask";
import {InputContainer} from "../UI/InputContainer/InputContainer";

export const InputPhone = (props) => {
  const { inputData, formFields, onChange, style, ...otherProps } = props;

  const {fieldId: name, label_ru} = inputData

  const value = formFields[name.toString()];

  const mask = inputData.inputMask?.replace(/[0-9]/g, "9").replace(/-/g, " ");
  const maskWithPrefix = inputData.prefix
    ? inputData.prefix.replace(/9/g, "\\9") + " " + mask
    : mask;

  return (
    <InputContainer>
      <label htmlFor={name}>{label_ru}</label>
      <InputMask
        className={style.FormInput}
        id={name}
        name={name}
        placeholder={label_ru || "Номер телефона"}
        value={value}
        onChange={onChange}
        mask={maskWithPrefix}
        maskChar={null}
        {...otherProps}
      />
    </InputContainer>
  );
};
