import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { FormProvider } from "./context/form.context";
import { CatalogProvider } from "./context/services.context";
import App from "./App";
import "./index.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <CatalogProvider>
      <FormProvider>
        <App />
      </FormProvider>
    </CatalogProvider>
  </BrowserRouter>
);
