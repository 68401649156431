import {apiAxiosInstance} from "./apiInstance";

export const fetchServices = async (setServices) => {
  try {
    let response = await apiAxiosInstance.get("/api/services");
    const { data } = response;

    if (response.status !== 200) {
      throw new Error(`${response.status} ${response.statusText}`);
    }

    const categories = data.map(service => service.data.parents.slice(-1)[0]).filter(x => x);
    const uniqueCategories = [
      ...new Map(categories.map((item) => [item["id"], item])).values(),
    ];

    setServices({
      catalog: data,
      categories: uniqueCategories
    });

  } catch (error) {
    console.error(error);
  }
};
