import React, { createContext, useState } from "react";

export const ServicesContext = createContext({
  services: null,
  setServices: () => null,
});

export const CatalogProvider = ({ children }) => {
  const [services, setServices] = useState(null);
  const value = { services, setServices };

  return <ServicesContext.Provider value={value}>{children}</ServicesContext.Provider>;
};
