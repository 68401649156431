import cls from './FindItem.module.scss'
import {Link} from "react-router-dom";

export const FindItem = ({item}) => {
  return (
    <Link className={cls.FindItem} to={`/pay/${item.id}`}>
      <div className={cls.image}>
        <img src={item.data.logo.url} alt="icon"/>
      </div>
      <div>{item.data.name_ru}</div>
    </Link>
  );
};
