import { useEffect, useState } from "react";
import {FindItem} from "../UI/FindItem/FindItem";
import searchIcon from "../../assets/images/IconSearch.svg";

export const SearchCatalog = (props) => {
  const {catalog, style} = props;
  const [value, setValue] = useState("");
  const [showResult, setShowResult] = useState(false);
  const [resultSearch, setResultSearch] = useState([]);

  useEffect(() => {
    value ? setShowResult(true) : setShowResult(false);

    const results = catalog.filter((item) =>
      item.data.name_ru.toLowerCase().includes(value.toLowerCase())
    );
    setResultSearch(results);
  }, [value, catalog]);

  return (
    <div className={style.SearchCatalog}>
      <form>
        <img className={style.searchIcon} src={searchIcon} alt="search" />
        <input
          className={style.input}
          type="text"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          placeholder="Выбирайте услугу для онлайн оплаты"
        />
        {showResult && (
          <div className={style.searchResult}>
            {resultSearch &&
              resultSearch.map((item) => (
                <FindItem key={item.id} item={item} />
              ))}
          </div>
        )}
      </form>
    </div>
  );
};
