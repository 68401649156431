import {apiAxiosInstance} from "./apiInstance";
import {iOSSafari} from "../helpers/detectBrowsers";

export const sendSbpForm = async (formData) => {
  let windowReference

  try {
    if (iOSSafari) {
      windowReference = window.open("/files/loader.html", "_blank");
    }

    const {data} = await apiAxiosInstance.post("/api/orders", {
      amount: formData.amount,
      currency: formData.currency,
      service_id: "megacom-kg",
      extra: "sbp",
      user_ref: formData.user_ref
    });

    if (data.url) {
      iOSSafari && (windowReference.location = data.url);

      return data.url;
    }

  } catch (error) {
    console.error(error);
  }
}
