import { useEffect } from "react";

export const InputHidden = (props) => {
  const { inputData, formFields, setFormFields, style, ...otherProps } = props;

  const name = inputData.fieldId;
  const value = formFields[name.toString()];

  useEffect(()=> {
    setFormFields({ ...formFields, [name]: inputData.value });
  },[])

  return (
    <input
      id={name}
      name={name}
      type="hidden"
      value={value}
      {...otherProps}
    />
  );
};