import cls from './NavBar.module.scss';
import logoMts from '../../assets/image/Logo.svg';
import { WidthLimiter } from '../UI/WidthLimiter/WidthLimiter';

const NavBar = () => {
  return (
    <header className={cls.NavBar}>
      <WidthLimiter>
        <div className={cls.container}>
          <img src={logoMts} alt="logo" />
          <nav className={cls.navLinks}>
            <ul className={cls.list}>
              <li>
                <a href={`https://${process.env.REACT_APP_BASE_URL}`} className={cls.link}>
                  На главную
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </WidthLimiter>
    </header>
  );
};

export default NavBar;
