import CatalogBlock from "../../components/landing/CatalogBlock/CatalogBlock";
import CategoriesBlock from "../../components/landing/CategoriesBlock/CategoriesBlock";

const HomePage = () => {
  return (
    <>
      <CatalogBlock />
      <CategoriesBlock/>
    </>
  );
};

export default HomePage;
