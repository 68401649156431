import React from "react";
import cls from "./CatalogBlock.module.scss";

const CatalogList = (props) => {
  const {catalog} = props;
  const {protocol, host} = window.location;

  return (
    <div className={cls.catalog}>
      {catalog.map((item) => {
        const subRoute = item.subdomain ? item.subdomain + "." : "";

        return (
          <a
            key={item.id}
            className={cls.item}
            href={
              `${protocol}//${subRoute}${host}/pay/${item.id}`
            }
          >
            <div className={cls.image}>
              <img src={item.data.logo.url} alt="icon"/>
            </div>
            <p>{item.data.name_ru}</p>
          </a>
        )
      })}
    </div>
  );
};

export default CatalogList;
