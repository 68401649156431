import MaskedInput from "react-text-mask";

export const InputWithMask = (props) => {
  const { mask, placeholder, type="text", style, error, ...otherProps } = props;
  const inputStyle = error ? `${style.FormInput} ${style.error}`
    : style.FormInput

  return (
    <MaskedInput
      className={inputStyle}
      type={type}
      placeholder={placeholder}
      guide={false}
      mask={mask}
      {...otherProps}
    />
  );
};
