export const cardMask = [
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  " ",
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  " ",
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  " ",
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/
];
export const monthYearMask = [/[0-9]/, /[0-9]/, "/", /[0-9]/, /[0-9]/];
