import {InputContainer} from "../UI/InputContainer/InputContainer";

export const InputMoney = (props) => {
  const { inputData, formFields, onChange, itemData, min, max, style, ...otherProps } = props;
  const {fieldId: name, label_ru} = inputData

  const value = formFields[name.toString()];

  return (
    <InputContainer>
      <label htmlFor={name}>{label_ru}</label>
      <input
        className={style.FormInput}
        type="number"
        max={max}
        min={min}
        id={name}
        name={name}
        placeholder={label_ru || "Сумма"}
        value={value}
        onChange={onChange}
        required
        {...otherProps}
      />
    </InputContainer>
  );
};
