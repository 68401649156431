
export const FormInput = (props) => {
  const { type = "text", name, value, onChange, placeholder, isError, style, ...otherProps } = props;

  const inputStyle = `${style.FormInput} ${isError ? style.error : ''}`

  return (
    <input
      className={inputStyle}
      id={name}
      name={name}
      type={type}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      {...otherProps}
    />
  );
};
