import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ServicesContext } from '../../../context/services.context';
import { Loading } from '@supermegapay/common';
import cls from './CategoriesBlock.module.scss';

const CategoriesBlock = () => {
  const { services } = useContext(ServicesContext);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    services && setCategories(services.categories);
  }, [services]);

  return (
    <>
      {
        categories.length > 0 &&
        <div className={cls.CategoriesBlock}>
          <div className={cls.container}>
            <h2 className={cls.title}>
              Услуги
            </h2>
            {
              !categories
                ? (<Loading />)
                : (<div className={cls.catalog}>
                  {categories.map((item) => (
                    <Link key={item.id} className={cls.item} to={`/services/${item.id}`}>
                      <div className={cls.image}>
                        <img src={item.logo.url} alt="icon" />
                      </div>
                      <h4>{item.name_ru}</h4>
                    </Link>
                  ))}
                </div>)
            }
          </div>
        </div>
      }
    </>
  );
};

export default CategoriesBlock;
