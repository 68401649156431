import {apiAxiosInstance} from "./apiInstance";

export const subscribe = async (setResultData, uuid, callbackFn) => {
  try {
    const {data} = await apiAxiosInstance.get(`/api/orders/${uuid}`);

    if (data.currency) {
      setResultData({
        title: data.state.title,
        style: data.state.style,
        order: data.user_ref,
        amount: data.amount,
        currency: data.currency
      })
    } else {
      setResultData({
        title: data.state.title,
        style: data.state.style,
        order: data.user_ref,
        amount: data.amount
      })
    }

    if (data.state.final === true) {
      callbackFn && callbackFn()
      return;
    }

  } catch (e) {
    console.log("error", e);
  }

  setTimeout(() => {
    subscribe(setResultData, uuid, callbackFn);
  }, 3000);
};
