import React, { createContext, useState } from "react";

export const FormContext = createContext({
  formData: null,
  setFormData: () => null,
});

export const FormProvider = ({ children }) => {
  const [formData, setFormData] = useState(null);
  const value = { formData, setFormData };

  return <FormContext.Provider value={value}>{children}</FormContext.Provider>;
};
