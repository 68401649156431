import cls from './Footer.module.scss';
import logoMTC from '../../assets/image/LogoMtcLight.svg';
import oferta from '../../assets/files/Оферта.pdf';
import policy from '../../assets/files/Политика.pdf';
import { WidthLimiter } from '../UI/WidthLimiter/WidthLimiter';

const Footer = () => {
  return (
    <div className={cls.Footer}>
      <WidthLimiter>
        <div className={cls.container}>
          <div className={cls.logo}>
            <img src={logoMTC} alt="logo" />
            <p>Copyright ©2011 m&tc.kg. All rights reserved</p>
          </div>
          <div className={cls.contacts}>
            <p>Общество с ограниченной ответственностью «Mobile & Television Communications» (Мобайл энд Телевижн
              Коммуникейшнс)</p>
            <p><b>Адрес:</b> Кыргызская Республика, г. Бишкек, ул. Исанова 42/2, офис 405, 720001.</p>
            <a href="mailto:info@mtc.kg"><b>Email:</b> info@mtc.kg</a>
            <a href="tel:+996312887858"><b>Тел./факс:</b> + 996 312 88 78 58 с 09.00 до 20.00</a>
            <a href={oferta} target="_blank" rel="noreferrer"><b>Публичная оферта</b></a>
            <a href={policy} target="_blank" rel="noreferrer"><b>Политика обработки файлов cookie</b></a>
          </div>
          <div className={cls.license}>
            <b>Лицензии Национального Банка Кыргызской Республики от 24 августа 2023 года:</b>
            <p><b>№ 2012120615</b> – на оказание услуг по приему, бработке и выдаче финансовой информации (процессинг,
              клиринг) по платежам и расчетам третьих лиц участникам платежной системы, данного процессингового,
              клирингового центра.</p>
            <p><b>№ 3011120615</b> – на оказание услуг по приему и проведению платежей и расчетов за товары и услуги, не
              являющиеся результатом своей деятельности, в пользу третьих лиц посредством платежных систем, основанных
              на информационных технологиях, электронных средствах и способах проведения платежей.</p>
            <p><b>ИНН:</b> 02506201010028</p>
            <b>Банковские реквизиты:</b>
            <p>Филиал ОАО "Оптима Банк" в г. Бишкек №5</p>
            <p>р\с 1091620157690159, БИК:109016</p>
            <p><b>Номер телефона:</b> 0312 887-858</p>
          </div>
        </div>
      </WidthLimiter>
    </div>
  );
};

export default Footer;
