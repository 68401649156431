import { InputContainer } from "../UI/InputContainer/InputContainer";
import { useEffect } from "react";
import { ReactComponent as MC } from '../../assets/images/svg/mastercard.svg'
import { ReactComponent as Visa } from '../../assets/images/svg/Visa.svg'
import { ReactComponent as Mir } from '../../assets/images/svg/mir.svg'
import { ReactComponent as SBP } from '../../assets/images/svg/sbp.svg'
import cls from './SelectCompt.module.scss'

export const SelectCompt = (props) => {
  const { changeOption, paymentOption, options, style } = props;

  const changeSelect = (e) => {
    const newOption = options.find((option) => option.value === e.target.value)
    const { value, min, max, comissions, currency = 'RUB' } = newOption;

    changeOption(value, comissions.value, min, max, currency)
  };

  useEffect(() => {
    const { value, comissions, min, max, currency = 'RUB' } = options[0];
    changeOption(value, comissions.value, min, max, currency)
  }, [])

  return (
    <>
      <InputContainer>
        <label htmlFor="cardSelect">
          Выберите источник платежа
        </label>
        <select
          id="cardSelect"
          value={paymentOption}
          onChange={changeSelect}
          className={style.FormInput}
        >
          {
            options.map((item) => (
              <option key={item.value} value={item.value}>
                {item.title}
              </option>
            ))
          }
        </select>
      </InputContainer>
      <div>
        <ul className={cls.payment_method_wrapp}>
          <li className={cls.payment_method}>
            <MC />
          </li>
          <li className={cls.payment_method}>
            <Visa />
          </li>
          <li className={cls.payment_method}>
            <Mir />
          </li>
          <li className={cls.payment_method}>
            <SBP />
          </li>
        </ul>
      </div>
    </>
  );
};
