import cls from "./InputContainer.module.scss";

export const InputContainer = (props) => {
  const { children } = props;

  return (
    <div className={cls.field}>
      {children}
    </div>
  );
};
