import React, { useContext, useEffect, useState } from "react";
import {FormTitle,Button,FormInput, InputWithMask} from "../index";
import {cardMask, monthYearMask, sendForm} from "../../utils";
import cls from "./Form3.module.scss";
import {Navigate} from "react-router-dom";
import { checkLuhn } from '../../utils/helpers/cardValidateLuhn';

const defaultCardFields = {
  card_number: "",
  mmyy: "",
  cvc: "",
  username: ""
};

export const Form3 = (props) => {
  const {formContextProp, inputStyle, captchaToken, checkCaptchaAsync} = props;
  const [cardFields, setCardFields] = useState(defaultCardFields);
  const { card_number, mmyy, cvc, username } = cardFields;
  const { formData } = useContext(formContextProp);
  const [cardValid, setCardValid] = useState(true);

  const [buttonDisable, setButtonDisable] = useState(true);
  const [showLoader, setShowLoader] = useState(false);

  const onChangeForm = (e) => {
    const { name, value } = e.target;
    if (name === 'card_number') {
      setCardValid(checkLuhn(value));
    }
    setCardFields({ ...cardFields, [name]: value });
  };

  const onSubmitForm = async (e) => {
    e.preventDefault();

    if (!captchaToken) {
      await checkCaptchaAsync()
    }

    setShowLoader(true);
    setButtonDisable(true);

    const fullFormData = {
      ...formData, // из 1 формы
      ...cardFields // из последней формы
    };

    sendForm(fullFormData);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    cardValid && mmyy && cvc && username
      ? setButtonDisable(false)
      : setButtonDisable(true);
  }, [cardValid, mmyy, cvc, username]);

  if (formData.card_type === "3") {
    return <Navigate to={`/result?user_ref=${formData.user_ref}`}/>;
  }

  return (
    <form onSubmit={onSubmitForm}>
      <FormTitle>Оплата</FormTitle>
      <div className={cls.container}>
        <div className={cls.card}>
          <InputWithMask
            style={inputStyle}
            mask={cardMask}
            name="card_number"
            placeholder="Номер банковской карты"
            value={card_number}
            onChange={onChangeForm}
            error={!cardValid}

          />
          <div className={cls.rowField}>
            <div className={cls.date}>
              <InputWithMask
                style={inputStyle}
                mask={monthYearMask}
                name="mmyy"
                placeholder="ММ/ГГ"
                value={mmyy}
                onChange={onChangeForm}
              />
            </div>
            <div className={cls.cvc}>
              <FormInput
                style={inputStyle}
                name="cvc"
                placeholder="CVC/CVV"
                type="password"
                maxLength={3}
                value={cvc}
                onChange={onChangeForm}
              />
            </div>
          </div>
          <FormInput
            style={inputStyle}
            name="username"
            placeholder="Фамилия и имя на карте"
            value={username}
            onChange={onChangeForm}
          />
        </div>
        <Button isLoading={showLoader} disabled={buttonDisable}>
          {formData.amount} {formData.currency}
        </Button>
      </div>
    </form>
  );
};
