import {Loader} from "../Loader/Loader";
import cls from "./Loading.module.scss";

export const Loading = () => {
  return (
    <div className={cls.loader}>
      <Loader color={true} />
    </div>
  );
};
